<template>
    <div class="contactUp_page">
        <Banner :adCategory="9"></Banner>
        <div class="module1">
            <div class="content">
                <div class="title display">联系我们</div>
                <div class="contact flex">
                    <div class="fl">
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/1.png" alt="">
                            <div class="contact_way">
                                <div>报名热线</div>
                                <div>{{ $store.state.Information.telephone }}</div>
                            </div>
                        </div>
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/2.png" alt="">
                            <div class="contact_way">
                                <div>邮箱</div>
                                <div>{{ $store.state.Information.email }}</div>
                            </div>
                        </div>
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/3.png" alt="">
                            <div class="contact_way">
                                <div>邮政编码 </div>
                                <div>{{ $store.state.Information.zipCode }}</div>
                            </div>
                        </div>
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/4.png" alt="">
                            <div class="contact_way">
                                <div>学校地址</div>
                                <div>
                                    {{ $store.state.Information.schoolAddress }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="fr">

                        <!-- <iframe class="icon_map"
                            src="https://map.baidu.com/@12725892.334499454,2580974.66792332,17z/latlng%3D22.715282%252C114.317392%26title%3D%25E6%2588%2591%25E7%259A%2584%25E4%25BD%258D%25E7%25BD%25AE%26content%3D%25E6%259E%259C%25E5%25B0%2594%25E4%25BD%25B3%25E8%2581%258C%25E4%25B8%259A%25E6%258A%2580%25E8%2583%25BD%25E5%259F%25B9%25E8%25AE%25AD%25E5%25AD%25A6%25E6%25A0%25A1%26autoOpen%3Dtrue&ak='CEGnqD2sksloOAoq8zgiqbHPzvYqEZsC'"
                            frameborder="0"></iframe> -->
                        <iframe class="icon_map" src="https://www.golkia-school.com/words/schoolMap.html"
                            frameborder="0" scrolling="no"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
export default {
    components: {
        Banner
    },
    data(){
        return{

        }
    },
    mounted(){
        document.documentElement.scrollTop = 0;
        document.getElementById('TANGRAM__PSP_44__').style.display = 'none'
    }
}
</script>
<style lang="scss" scoped>








    .contactUp_page{
        .module1{
            height: 863px;
            .title {
                 font-size: 36px;
                 font-family: Microsoft YaHei;
                 font-weight: bold;
                 color: #333333;
                 line-height: 36px;
                 padding-top: 98px;
                 margin-bottom: 50px;
             }
             .contact{
                .fl{
                    width: 445px;
                    height: 560px;
                    padding-top: 66px;
                    background: #f4f5f5;
                    .list:nth-child(1){
                        .icon_img{
                            width: 51px;
                            height: 51px;
                            margin-right: 23px;
                        }
                    }
                    .list:nth-child(2){
                        .icon_img{
                            width: 52px;
                            height: 44px;
                            margin-right: 25px;
                        }
                    }
                    .list:nth-child(3){
                        .icon_img{
                            width: 52px;
                            height: 52px;
                            margin-right: 26px;
                        }
                    }
                    .list:nth-child(4){
                        .icon_img{
                            width: 50px;
                            height: 56px;
                            margin-right: 23px;
                        }
                    }
                    .list{
                        padding-left:50px;
                        box-sizing: border-box;
                        margin-bottom: 55px;
                        .icon_img{
                            width: 51px;
                            height: 51px;
                        }
                        .contact_way{
                            width: 286px;
                            font-size: 22px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #003C7E;
                            line-height: 30px;
                        }
                    }
                }
                .fr{
                    .icon_map{
                        width: 780px;
                        height: 560px;
                       
                    }
                                        .icon_map::-webkit-scrollbar {display:none}
                }
             }
        }
        
    }
</style>
<style>

</style>